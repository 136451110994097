import { Controller, useForm } from "react-hook-form";
import { Button, Col, Input, Label, Modal, ModalHeader, Row } from "reactstrap";
import FormHelper from "../../helpers/form";
import { useEffect, useState } from "react";
import Api from 'api/examinations'
import Employees from "../../api/employees";
import Patients from "../../api/patients";
import Missions from "../../api/missions";
import FlatPicker from "react-flatpickr";
import AddPatient from '../Patients/Add'
import AddEmployee from '../Employees/Add'
import AddMission from '../Missions/Add'
import Select from "react-select";

const Add = ({ fetchData, form, setData, data, selectedTeeth }) => {
    const { control, handleSubmit, setValue, getValues, watch, reset, setError, formState: { errors } } = useForm({
        defaultValues: {
            price: 0,
            note: '',
            doctor_id: null,
            patient_id: null,
            service_id: null,
            worker_id: null,
            discount_doctor: 0,
            discount_worker: 0,
            discount_doctor_number: 0,
            discount_worker_number: 0,
            discount_total: 0,
            admission_date: new Date(),
            expiration_date: new Date(),
            payment_date: new Date(),
            jaw_type:null,
        }
    })
    const [patients, setPatients] = useState([])
    const [employees, setEmployees] = useState([])
    const [missions, setMissions] = useState([])
    const [doctors, setDoctors] = useState([])
    const [loader, setLoader] = useState(false)
    const [patientForm, setPatientForm] = useState({})
    const [employeeForm, setEmployeeForm] = useState({})
    const [missionForm, setMissionForm] = useState({})

    // İzlenen alanlar
    const discountDoctor = watch("discount_doctor");
    const discountWorker = watch("discount_worker");
    const discountDoctorNumber = watch("discount_doctor_number");
    const discountWorkerNumber = watch("discount_worker_number");
    const discountTotal = watch("discount_total");

    // Disable/readOnly durumlarını kontrol et
    const isDoctorDiscountActive =
        Number(discountDoctorNumber) > 0 || Number(discountTotal) > 0 || Number(discountWorkerNumber) > 0;
    const isWorkerDiscountActive =
        Number(discountWorkerNumber) > 0 || Number(discountTotal) > 0 || Number(discountDoctorNumber) > 0;

    const isDoctorNumberActive = Number(discountDoctor) > 0 || Number(discountWorker) > 0;
    const isWorkerNumberActive = Number(discountWorker) > 0 || Number(discountDoctor) > 0;

    const submit = async values => {
        setLoader(true)
        try {
            await Api[values?.id ? 'update' : 'add']({
                ...FormHelper.validateBody(values),
                admission_date: FormHelper.convertForBackend(values?.admission_date),
                expiration_date: FormHelper.convertForBackend(values?.expiration_date),
                payment_date: FormHelper.convertForBackend(values?.payment_date),
            })
            fetchData(true, values?.patient_id?.value)
            reset()
            setValue('patient_id', values?.patient_id)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchEmployees = async () => {
        const { data } = await Employees.getSelect()
        setEmployees(data)
    }

    const fetchDoctors = async () => {
        const { data } = await Employees.getDoctors()
        setDoctors(data)
    }

    const fetchPatients = async () => {
        const { data } = await Patients.getSelect()
        setPatients(data)
    }

    const fetchMissions = async () => {
        const { data } = await Missions.getSelect()
        setMissions(data)
    }

    const fetchModalData = async () => {
        await Promise.all([fetchEmployees(), fetchPatients(), fetchMissions(), fetchDoctors()])
    }

    useEffect(() => {
        if (form?.data) {
            reset(form?.data)
        }
    }, [form])

    useEffect(() => {
        fetchModalData()
    }, [])

    const saveAsDraft = (e) => {
        e.preventDefault()
        const values = getValues()
        const newData = { ...data }

        if (selectedTeeth && selectedTeeth.length > 0) {
            selectedTeeth.forEach(toothId => {
                newData.examinations = [
                    ...newData.examinations || [],
                    {
                        patient: values?.patient_id?.label,
                        doctor: values?.doctor_id?.label,
                        worker: values?.worker_id?.label,
                        service: values?.service_id?.label,
                        ...FormHelper.validateBody(values),
                        patient_id: values?.patient_id?.value,
                        admission_date: FormHelper.convertForBackend(values?.admission_date),
                        expiration_date: FormHelper.convertForBackend(values?.expiration_date),
                        payment_date: FormHelper.convertForBackend(values?.payment_date),
                        tooth_id: toothId,
                        final_price: (
                            (values?.price - Number(values?.discount_total || 0)) - // Öncelikle toplam indirim
                            ((values?.price - Number(values?.discount_total || 0)) * (Number(values?.discount_doctor || 0) + Number(values?.discount_worker || 0))) / 100 - // Kalan fiyata yüzdesel indirim
                            Number(values?.discount_doctor_number || 0) - // Doğrudan rakamsal indirim
                            Number(values?.discount_worker_number || 0) // Doğrudan rakamsal indirim
                        ).toFixed(2)


                    }
                ]
            })
        } else {
            newData.examinations = [
                ...newData.examinations || [],
                {
                    patient: values?.patient_id?.label,
                    doctor: values?.doctor_id?.label,
                    worker: values?.worker_id?.label,
                    service: values?.service_id?.label,
                    ...FormHelper.validateBody(values),
                    patient_id: values?.patient_id?.value,
                    admission_date: FormHelper.convertForBackend(values?.admission_date),
                    expiration_date: FormHelper.convertForBackend(values?.expiration_date),
                    payment_date: FormHelper.convertForBackend(values?.payment_date),
                    final_price: (
                        (values?.price - Number(values?.discount_total || 0)) - // Öncelikle toplam indirim
                        ((values?.price - Number(values?.discount_total || 0)) * (Number(values?.discount_doctor || 0) + Number(values?.discount_worker || 0))) / 100 - // Kalan fiyata yüzdesel indirim
                        Number(values?.discount_doctor_number || 0) - // Doğrudan rakamsal indirim
                        Number(values?.discount_worker_number || 0) // Doğrudan rakamsal indirim
                    ).toFixed(2)



                }
            ]
        }

        setData(newData)
    }

    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={patientForm?.status}
                toggle={() => setPatientForm({})}>
                <ModalHeader
                    toggle={() => setPatientForm({})}>{patientForm?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <AddPatient fetchData={fetchPatients} form={patientForm} setForm={setPatientForm} />
            </Modal>
            <Modal size="xl" className="modal-dialog-centered" isOpen={employeeForm?.status}
                toggle={() => setEmployeeForm({})}>
                <ModalHeader
                    toggle={() => setEmployeeForm({})}>{employeeForm?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <AddEmployee fetchData={fetchEmployees} form={employeeForm} setForm={setEmployeeForm} />
            </Modal>
            <Modal className="modal-dialog-centered" isOpen={missionForm?.status}
                toggle={() => setMissionForm({})}>
                <ModalHeader
                    toggle={() => setMissionForm({})}>{missionForm?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <AddMission fetchData={fetchMissions} form={missionForm} setForm={setMissionForm} />
            </Modal>
            <form onSubmit={handleSubmit(submit)}>
                <Row>
                    <Col sm={12} md={4}>
                        <div className="mb-3">
                            <Label for="patient_id">Pasient</Label>
                            <div className="d-flex align-items-center gap-2">
                                <Controller rules={{ required: true }} name="patient_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <div className="w-100">
                                            <Select
                                                options={patients}
                                                placeholder=""
                                                className={`w-100 ${errors?.patient_id && 'is-invalid'}`}
                                                doctor_id
                                                onChange={onChange}
                                                value={value}
                                                name="patient_id"
                                                id="patient_id" />
                                            {FormHelper.generateFormFeedback(errors, 'patient_id')}
                                        </div>
                                    )} />
                                <Button type="button" onClick={() => setPatientForm({ status: true })}
                                    color="primary">
                                    <i className="bx bx-plus" />
                                </Button>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label for="doctor_id">Həkim</Label>
                            <div className="d-flex align-items-center gap-2">
                                <Controller rules={{ required: true }} name="doctor_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            options={doctors}
                                            placeholder=""
                                            className={`w-100 ${errors?.doctor_id && 'is-invalid'}`}
                                            onChange={onChange}
                                            value={value}
                                            name="doctor_id"
                                            id="doctor_id" />
                                    )} />
                                <Button type="button" onClick={() => setEmployeeForm({ status: true })}
                                    color="primary">
                                    <i className="bx bx-plus" />
                                </Button>
                            </div>
                            {FormHelper.generateFormFeedback(errors, 'doctor_id')}
                        </div>
                        <div className="mb-3">
                            <Label for="worker_id">Göndərən şəxs</Label>
                            <div className="d-flex align-items-center gap-2">
                                <Controller rules={{ required: true }} name="worker_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            options={employees}
                                            placeholder=""
                                            className={`w-100 ${errors?.worker_id && 'is-invalid'}`}
                                            onChange={onChange}
                                            value={value}
                                            name="worker_id"
                                            id="worker_id" />
                                    )} />
                                <Button type="button" onClick={() => setEmployeeForm({ status: true })}
                                    color="primary">
                                    <i className="bx bx-plus" />
                                </Button>
                            </div>
                            {FormHelper.generateFormFeedback(errors, 'worker_id')}
                        </div>
                        <div className="mb-3">
                            <Label for="service_id">Xidmət</Label>
                            <div className="d-flex align-items-center gap-2">
                                <Controller rules={{ required: true }} name="service_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            options={missions}
                                            placeholder=""
                                            className={`w-100 ${errors?.service_id && 'is-invalid'}`}
                                            onChange={e => {
                                                onChange(e)
                                                setValue('price', missions?.find(item => item?.value == e?.value)?.price)
                                            }}
                                            value={value}
                                            name="service_id"
                                            id="service_id" />
                                    )} />
                                <Button type="button" onClick={() => setMissionForm({ status: true })}
                                    color="primary">
                                    <i className="bx bx-plus" />
                                </Button>
                            </div>
                            {FormHelper.generateFormFeedback(errors, 'service_id')}
                        </div>
                        <div className="mb-3">
                            <Label for="jaw_type">Çənə Seçimi</Label>
                            <Controller
                                name="jaw_type"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <div className="d-flex align-items-center gap-2">
                                        <Select
                                            options={[
                                                
                                                { value: 'Tam çənə', label: 'Tam çənə' },
                                                { value: 'Alt çənə', label: 'Alt çənə' },
                                                { value: 'Üst çənə', label: 'Üst çənə' },
                                                { value: 'Üst sağ', label: 'Üst sağ' },
                                                { value: 'Üst sol', label: 'Üst sol' },
                                                { value: 'Alt sağ', label: 'Alt sağ' },
                                                { value: 'Alt sol', label: 'Alt sol' },
                                                
                                            ]}
                                            placeholder="Seçin"
                                            isClearable // Seçimi temizlemek için özelliği etkinleştirir
                                            className={`w-100 ${errors?.jaw_type && 'is-invalid'}`}
                                            onChange={onChange}
                                            value={value}
                                            id="jaw_type"
                                        />
                                    </div>
                                )}
                            />
                            {FormHelper.generateFormFeedback(errors, 'jaw_type')}
                        </div>

                    </Col>
                    <Col sm={12} md={4}>
                        <Row>
                            <Col sm={6}>
                                <div className="mb-3">
                                    <Label for="price">Qiymət</Label>
                                    <Controller
                                        rules={{ required: true }}
                                        name="price"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                type="number"
                                                name="price"
                                                id="price"
                                                disabled
                                                value={value}
                                                onChange={onChange}
                                                className={errors?.price && 'is-invalid'}
                                            />
                                        )}
                                    />
                                    {FormHelper.generateFormFeedback(errors, 'price')}
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3">
                                    <Label for="discount_total">Ümumi Endirim</Label>
                                    <Controller
                                        rules={{
                                            min: { value: 0, message: 'Minimum 0 olmalıdır' },
                                            max: { value: 100, message: 'Maksimum 100 olmalıdır' }
                                        }}
                                        name="discount_total"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                type="number"
                                                name="discount_total"
                                                id="discount_total"
                                                value={value}
                                                onChange={onChange}
                                                disabled={isDoctorNumberActive || isWorkerNumberActive}
                                                className={errors?.discount_total && 'is-invalid'}
                                            />
                                        )}
                                    />
                                    {FormHelper.generateFormFeedback(errors, 'discount_total')}
                                </div>
                            </Col>

                        </Row>

                        <div className="mb-3">
                            <Label for="discount_doctor">Qəbul edən həkim endirim faizi</Label>
                            <Controller
                                rules={{
                                    min: { value: 0, message: 'Minimum 0 olmalıdır' },
                                    max: { value: 100, message: 'Maksimum 100 olmalıdır' },
                                }}
                                name="discount_doctor"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="number"
                                        name="discount_doctor"
                                        id="discount_doctor"
                                        value={value}
                                        onChange={onChange}
                                        disabled={isDoctorDiscountActive} // Dinamik olarak devre dışı bırakılır
                                        className={errors?.discount_doctor && 'is-invalid'}
                                    />
                                )}
                            />
                            {FormHelper.generateFormFeedback(errors, 'discount_doctor')}
                        </div>

                        <div className="mb-3">
                            <Label for="discount_worker">Göndərən şəxs endirim faizi</Label>
                            <Controller
                                rules={{
                                    min: { value: 0, message: 'Minimum 0 olmalıdır' },
                                    max: { value: 100, message: 'Maksimum 100 olmalıdır' },
                                }}
                                name="discount_worker"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="number"
                                        name="discount_worker"
                                        id="discount_worker"
                                        value={value}
                                        onChange={onChange}
                                        disabled={isWorkerDiscountActive} // Dinamik olarak devre dışı bırakılır
                                        className={errors?.discount_worker && 'is-invalid'}
                                    />
                                )}
                            />
                            {FormHelper.generateFormFeedback(errors, 'discount_worker')}
                        </div>

                        <div className="mb-3">
                            <Label for="discount_doctor_number">Qəbul edən həkim endirim rəqəmlə</Label>
                            <Controller
                                rules={{
                                    min: { value: 0, message: 'Minimum 0 olmalıdır' },
                                    max: { value: 100, message: 'Maksimum 100 olmalıdır' },
                                }}
                                name="discount_doctor_number"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="number"
                                        name="discount_doctor_number"
                                        id="discount_doctor_number"
                                        value={value}
                                        onChange={onChange}
                                        disabled={isDoctorNumberActive} // Dinamik olarak devre dışı bırakılır
                                        className={errors?.discount_doctor_number && 'is-invalid'}
                                    />
                                )}
                            />
                            {FormHelper.generateFormFeedback(errors, 'discount_doctor_number')}
                        </div>

                        <div className="mb-3">
                            <Label for="discount_worker_number">Göndərən şəxs endirim rəqəmlə</Label>
                            <Controller
                                rules={{
                                    min: { value: 0, message: 'Minimum 0 olmalıdır' },
                                    max: { value: 100, message: 'Maksimum 100 olmalıdır' },
                                }}
                                name="discount_worker_number"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="number"
                                        name="discount_worker_number"
                                        id="discount_worker_number"
                                        value={value}
                                        onChange={onChange}
                                        disabled={isWorkerNumberActive} // Dinamik olarak devre dışı bırakılır
                                        className={errors?.discount_worker_number && 'is-invalid'}
                                    />
                                )}
                            />
                            {FormHelper.generateFormFeedback(errors, 'discount_worker_number')}
                        </div>


                    </Col>
                    <Col sm={12} md={4}>
                        <div className="mb-3">
                            <Label for="admission_date">Qəbul tarixi</Label>
                            <Controller name="admission_date" control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FlatPicker
                                        className="form-control d-block"
                                        value={value}
                                        onChange={onChange}
                                        options={{
                                            locale: 'az'
                                        }}
                                    />
                                )} />
                            {FormHelper.generateFormFeedback(errors, 'admission_date')}
                        </div>

                        <div className="mb-3">
                            <Label for="note">Qeyd</Label>
                            <Controller name="note" control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="textarea"
                                        rows={5}
                                        name="note"
                                        id="note"
                                        value={value}
                                        onChange={onChange}
                                        className={errors?.note && 'is-invalid'}
                                    />
                                )} />
                            {FormHelper.generateFormFeedback(errors, 'note')}
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end gap-1">
                    <Button type="submit" onClick={saveAsDraft} color="success">
                        Əlavə et
                    </Button>
                </div>
            </form>
        </>
    )
}

export default Add