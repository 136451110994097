import React, { useState, useEffect, useRef } from "react";
import "./TeethMap.css";

const TeethMap = ({ onChange }) => {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 1000, height: 800 }); 
  const [teeth, setTeeth] = useState([]);
  const [selectedTeeth, setSelectedTeeth] = useState([]);
  const [isChildTeeth, setIsChildTeeth] = useState(false);

  const updateContainerSize = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  };

  useEffect(() => {
    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);

    return () => window.removeEventListener("resize", updateContainerSize);
  }, []);

  useEffect(() => {
    setTeeth((prevTeeth) => {
      const defaultTeeth = isChildTeeth ? getChildTeeth() : getDefaultTeeth();
      return defaultTeeth.map((tooth) => ({
        ...tooth,
        opacity: selectedTeeth.includes(tooth.id) ? 1 : tooth.opacity, 
      }));
    });
  }, [containerSize, selectedTeeth, isChildTeeth]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedTeeth);
    }
  }, [selectedTeeth, onChange]);

  const handleImageLoad = () => {
    updateContainerSize();
  };

  const handleToothClick = (id) => {
    setTeeth((prevTeeth) =>
      prevTeeth.map((tooth) =>
        tooth.id === id
          ? { ...tooth, opacity: tooth.opacity === 1 ? 0 : 1 }
          : tooth
      )
    );

    setSelectedTeeth((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((toothId) => toothId !== id)
        : [...prevSelected, id]
    );
  };

  const calculateStyle = (style) => {
    const { width, height } = containerSize;
    const originalWidth = 1000; 
    const originalHeight = 800; 

    const top = (style.top / originalHeight) * height;
    const left = (style.left / originalWidth) * width;
    const size = (style.size / originalWidth) * width;

    return {
      position: "absolute",
      top: `${top}px`,
      left: `${left}px`,
      width: `${size}px`,
      height: `${size}px`,
      opacity: style.opacity,
      cursor: "pointer",
      transition: "opacity 0.3s",
      pointerEvents: "auto",
    };
  };

  const imagePath = (id) => require(`../../assets/images/tooths/${id}.png`);

  return (
    <div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={isChildTeeth}
            onChange={(e) => setIsChildTeeth(e.target.checked)}
          />
          <span className="checkbox-label">Uşaq çənəsi</span>
        </label>
      </div>

      <div ref={containerRef} className="main-image-container">
        <img
          src={isChildTeeth ? require("../../assets/images/tooths/child.png") : require("../../assets/images/tooths/tooth.png")}
          alt="Teeth Map"
          onLoad={handleImageLoad}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
        {teeth.map((tooth) => (
          <img
            key={tooth.id}
            src={imagePath(tooth.id)}
            alt={`Tooth ${tooth.id}`}
            className="tooth"
            style={calculateStyle(tooth)}
            onClick={() => handleToothClick(tooth.id)}
          />
        ))}
      </div>
    </div>
  );
};

const getDefaultTeeth = () => [

  { id: 18, top: 84, left: 92, size: 91, opacity: 0 },  
  { id: 17, top: 70, left: 139, size: 96, opacity: 0 },
  { id: 16, top: 70, left: 194, size: 92, opacity: 0 }, 
  { id: 15, top: 64, left: 240, size: 97, opacity: 0 }, 
  { id: 14, top: 63, left: 282.5, size: 97, opacity: 0 },
  { id: 13, top: 25, left: 313, size: 118, opacity: 0 }, 
  { id: 12, top: 53, left: 364, size: 100, opacity: 0 },
  { id: 11, top: 50, left: 402, size: 100, opacity: 0 }, 

  { id: 28, top: 84, left: 816, size: 91, opacity: 0 },  
  { id: 27, top: 70, left: 764, size: 96, opacity: 0 },
  { id: 26, top: 70, left: 713, size: 92, opacity: 0 }, 
  { id: 25, top: 62, left: 663, size: 97, opacity: 0 }, 
  { id: 24, top: 63, left: 620, size: 97, opacity: 0 },
  { id: 23, top: 25, left: 568, size: 118, opacity: 0 }, 
  { id: 22, top: 53, left: 536, size: 100, opacity: 0 },
  { id: 21, top: 50, left: 497, size: 100, opacity: 0 }, 


  { id: 48, top: 502, left: 92, size: 77, opacity: 0 },  
  { id: 47, top: 496, left: 142, size: 83, opacity: 0 },
  { id: 46, top: 490, left: 192, size: 89, opacity: 0 }, 
  { id: 45, top: 486, left: 235, size: 97, opacity: 0 }, 
  { id: 44, top: 482, left: 279, size: 97, opacity: 0 },
  { id: 43, top: 474, left: 314, size: 114, opacity: 0 }, 
  { id: 42, top: 489, left: 362, size: 100, opacity: 0 },
  { id: 41, top: 480, left: 405, size: 97, opacity: 0 }, 


  { id: 38, top: 500, left: 832, size: 78, opacity: 0 },  
  { id: 37, top: 491, left: 773, size: 85, opacity: 0 },
  { id: 36, top: 490, left: 719, size: 89, opacity: 0 }, 
  { id: 35, top: 486, left: 667, size: 97, opacity: 0 }, 
  { id: 34, top: 482, left: 623, size: 97, opacity: 0 },
  { id: 33, top: 474, left: 571, size: 114, opacity: 0 }, 
  { id: 32, top: 489, left: 536, size: 100, opacity: 0 },
  { id: 31, top: 480, left: 496, size: 100, opacity: 0 }, 

];


const getChildTeeth = () => [
  { id: 51, top: 79, left: 432, size: 40, opacity: 0 },  
  { id: 52, top: 87, left: 392.5, size: 35, opacity: 0 },
  { id: 53, top: 45, left: 343, size: 53, opacity: 0 },
  { id: 54, top: 95, left: 310, size: 36, opacity: 0 },
  { id: 55, top: 95, left: 266, size: 36, opacity: 0 },


  { id: 61, top: 79, left: 525, size: 40, opacity: 0 },  
  { id: 62, top: 87, left: 569, size: 35, opacity: 0 },
  { id: 63, top: 40, left: 600, size: 55, opacity: 0 },
  { id: 64, top: 99, left: 651, size: 35, opacity: 0 },
  { id: 65, top: 95, left: 695, size: 36, opacity: 0 },


  { id: 81, top: 527, left: 434, size: 34, opacity: 0 },  
  { id: 82, top: 527, left: 396, size: 33, opacity: 0 },
  { id: 83, top: 518, left: 352, size: 38, opacity: 0 },
  { id: 84, top: 526, left: 312, size: 33, opacity: 0 },
  { id: 85, top: 527, left: 266, size: 35, opacity: 0 },

  { id: 71, top: 525, left: 527, size: 35, opacity: 0 },  
  { id: 72, top: 527, left: 567, size: 33, opacity: 0 },
  { id: 73, top: 518, left: 606, size: 39, opacity: 0 },
  { id: 74, top: 526, left: 652, size: 32, opacity: 0 },
  { id: 75, top: 528, left: 697, size: 32, opacity: 0 },

];
export default TeethMap;
