import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useState } from "react";
import Api from 'api/examinations';
import { Badge, Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import moment from "moment";
import Add from "./Add";
import { toast } from "react-toastify";
import TeethMap from './TeethMap';

const Examinations = () => {
    document.title = 'Müayinələr';
    const [confirmModal, setConfirmModal] = useState(false);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);

    const [selectedTeeth, setSelectedTeeth] = useState([]);

    const getColor = item => item?.debt > 0 ? { background: '#f46a6a', color: '#fff' } : { background: '#34c38f', color: '#fff' };

    const deleteData = async () => {
        await Api.delete(confirmModal);
        fetchData();
    };

    const fetchData = async (showLoader = true, id) => {
        setIsFetching(showLoader);
        const response = await Api.getByPatient(id);
        setData(response?.data);
        setIsFetching(false);
    };

    const removeData = index => {
        let newData = data?.examinations?.filter(item => !item?.id);
        newData = newData.filter((_, i) => i !== index);
        setData({
            ...data,
            examinations: newData
        });
    };

    
    const addBulk = async () => {
        setAddButtonIsDisabled(true);
        try {
            const draftExaminations = data?.examinations?.filter((item) => !item?.id);

            // if (!draftExaminations?.length || !selectedTeeth?.length) {
            //     toast.error("Bazaya əlavə ediləcək məlumat yoxdur!");
            //     setAddButtonIsDisabled(false);
            //     return;
            // }

            const examinationsToSend = draftExaminations.map((item, index) => {
                const toothIndex = index % selectedTeeth.length;
                return {
                    ...item,
                    tooth_id: selectedTeeth[toothIndex],
                };
            });

            await Api.addBulk({ examinations: examinationsToSend });

            fetchData(true, draftExaminations[0]?.patient_id);

        } catch (e) {
            toast.error('Xəta baş verdi: ' + (e.response?.data?.message || e.message || 'Bilinməyən xəta baş verdi!'));

        } finally {
            setAddButtonIsDisabled(false);
        }
    };

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData} />
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MÜAYİNƏLƏR (${data?.examinations?.length || 0})`} />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <TeethMap onChange={(val) => setSelectedTeeth(val)} />
                                <Add
                                    setData={setData}
                                    data={data}
                                    fetchData={fetchData}
                                    setForm={setForm}
                                    form={form}
                                    selectedTeeth={selectedTeeth}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg" />
                                </div>
                            ) : (
                                <CardBody className="mt-3" style={{ overflow: 'auto', maxHeight: '470px' }}>
                                    {data?.examinations?.some(item => !item?.id) && (
                                        <Button
                                            type="button"
                                            onClick={addBulk}
                                            disabled={addButtonIsDisabled}
                                            color="primary"
                                            className="mb-2"
                                        >
                                            {addButtonIsDisabled ? <Spinner size="sm" color="light" /> : 'Yadda saxla'}
                                        </Button>
                                    )}
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Pasiyent</th>
                                                    <th>Həkim</th>
                                                    <th>Göndərən şəxs</th>
                                                    <th>Xidmət</th>
                                                    <th>Diş</th>
                                                    <th>Çənə</th>
                                                    <th>Qiymət</th>
                                                    <th>Göndərən endirim %</th>
                                                    <th>Göndərən endirim rəqəmlə</th>
                                                    <th>Həkim endirim %</th>
                                                    <th>Həkim endirimi rəqəmlə</th>
                                                    <th>Ümumi endirim</th>
                                                    <th>Yekun qiymət</th>
                                                    {/* <th>Ödəniş</th>
                                                    <th>Borc</th> */}
                                                    <th>Qəbul tarixi</th>
                                                    <th>Qeyd</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.examinations?.map((item, index) => (
                                                    <tr key={item.id || index}>
                                                        <td style={getColor(item)}>{index + 1}</td>
                                                        <td style={getColor(item)}>{item?.patient}</td>
                                                        <td style={getColor(item)}>{item?.doctor}</td>
                                                        <td style={getColor(item)}>{item?.worker}</td>
                                                        <td style={getColor(item)}>{item?.service}</td>
                                                        <td style={getColor(item)}>{item?.tooth_id}</td>
                                                        <td style={getColor(item)}>{item?.jaw_type}</td>
                                                        <td style={getColor(item)}>{item?.price}</td>
                                                        <td style={getColor(item)}>{item?.discount_worker}</td>
                                                        <td style={getColor(item)}>{item?.discount_worker_number}</td>
                                                        <td style={getColor(item)}>{item?.discount_doctor}</td>
                                                        <td style={getColor(item)}>{item?.discount_doctor_number}</td>
                                                        <td style={getColor(item)}>{item?.discount_total}</td>
                                                        <td style={getColor(item)}>{item?.final_price}</td>
                                                        <td style={getColor(item)}>
                                                            {item?.admission_date && moment(item?.admission_date).format('DD.MM.YYYY')}
                                                        </td>
                                                        <td style={getColor(item)}>{item?.note}</td>
                                                        {!item?.id && (
                                                            <td>
                                                                <Button onClick={() => removeData(index)} color="danger" id={`delete-${item.id}`}>
                                                                    <i className="bx bx-trash" />
                                                                </Button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right' }}>Cəmi:</td>
                                                    <td>{data?.examinations?.reduce((sum, item) => sum + Number(item.price || 0), 0).toFixed(2)}</td>
                                                    <td></td>
                                                    <td>{data?.examinations?.reduce((sum, item) => sum + Number(item.discount_worker_number || 0), 0).toFixed(2)}</td>

                                                    <td></td>
                                                    <td>{data?.examinations?.reduce((sum, item) => sum + Number(item.discount_doctor_number || 0), 0).toFixed(2)}</td>

                                                    <td>{data?.examinations?.reduce((sum, item) => sum + Number(item.discount_total || 0), 0).toFixed(2)}</td>

                                                    <td>{data?.examinations?.reduce((sum, item) => sum + Number(item.final_price || 0), 0).toFixed(2)}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td colSpan="5"></td>
                                                </tr>
                                            </tfoot>

                                        </table>

                                    </div>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Examinations;
