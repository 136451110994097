import ReactPdf from "@react-pdf/renderer";
import RegularDejavu from '../Cashbox/DejaVuSans.ttf'
import BoldDejavu from '../Cashbox/DejaVuSans-Bold.ttf'
import ItalicDejavu from '../Cashbox/DejaVuSerif-Italic.ttf'
import Can from "../../components/Common/Can";
import moment from "moment";
import Logo from "../../assets/images/sidebar/logo.png";

const PDFTemplate = ({values, disabled, setPdf}) => {
    let data = []
    if (Array.isArray(values)) {
        data = values
    } else {
        data = [values]
    }
    ReactPdf.Font.register({
        family: "DejaVu Sans",
        fonts: [
            {src: RegularDejavu, fontWeight: 'normal'},
            {src: BoldDejavu, fontWeight: 'bold'},
            {src: ItalicDejavu, fontStyle: 'italic'},
        ]
    });

    const styles = ReactPdf.StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            fontFamily: 'DejaVu Sans',
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16
        },
        header: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        bold: {
            fontWeight: 'bold'
        },
        section: {
            margin: 10,
            padding: 10,
        },
        textCenter: {
            textAlign: "center"
        },
        justifyBetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            marginTop: 4,
            fontSize: 14,
        },
        divider: {
            height: 1,
            backgroundColor: '#000',
            width: '100%'
        },
        dividerText: {
            fontSize: 8,
            fontStyle: 'italic'
        },
        footer: {
            fontSize: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        table: {
            marginTop: 8,
            border: 1,
            borderColor: '#000',
        },
        tableHeaderRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#eff2ff',
        },
        tableHeaderCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 8,
            width: '24%',
            fontWeight: 'bold',
            borderRight: '1px solid black',
            borderBottom: '1px solid black'
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 7,
            width: '24%',
            borderRight: '1px solid black',
        }
    });

    return (
        <ReactPdf.BlobProvider document={<ReactPdf.Document>
            <ReactPdf.Page size="A4" style={styles.page}>
                <ReactPdf.View style={styles.section}>
                    <ReactPdf.Text style={styles.header}>
                        <ReactPdf.Image style={{
                            width: 200,
                            height: 30,
                            marginLeft: 80
                        }} src={Logo}/>
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.footer}>
                    <ReactPdf.Text style={styles.dividerText}>
                    Bakı şəh, Xalqlar Dostluğu, Məhəmməd Hadi 7,katv ilə üz-üzə. {'\n'} Tel:+994-055-218-88-10, +994-070-260-09-62.
                    </ReactPdf.Text>
                    <ReactPdf.Text style={styles.dividerText}>
                        {moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.divider}/>
                <ReactPdf.View style={{flexDirection: 'column', fontSize: 12}}>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{fontWeight: 'bold'}}>
                            Həkim:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{fontStyle: 'italic'}}>
                            {data[0]?.doctor_id?.label || data[0]?.doctor}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{fontWeight: 'bold'}}>
                            Pasient:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{fontStyle: 'italic'}}>
                            {data[0]?.patient_id?.label || data[0]?.patient}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{fontWeight: 'bold'}}>
                            Göndərən həkim:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{fontStyle: 'italic'}}>
                            {data[0]?.worker_id?.label || data[0]?.worker}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.table}>
                    <ReactPdf.View style={styles.tableHeaderRow}>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: '4%'}]}>№</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Xidmət</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Norma</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Nəticə</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {borderRight: 'none'}]}>Tarix</ReactPdf.Text>
                    </ReactPdf.View>
                    {values?.details?.map((patient, index) => (
                        <ReactPdf.View
                            style={[styles.tableRow, {borderBottom: `${index + 1 === values?.details.length ? 'none' : '1px solid #000'}`}]}
                            key={patient?.id}>
                            <ReactPdf.Text style={[styles.tableCell, {width: '4%'}]}>{index + 1}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={styles.tableCell}>{patient?.service_id?.label?.replace('- 0 AZN -', '-') || patient?.service_name?.replace('- 0 AZN -', '-')}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={styles.tableCell}>{patient?.service_id?.norm || patient?.norm || ''}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.result}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {borderRight: 'none'}]}>{moment(patient?.created_at).format('DD.MM.YYYY')}</ReactPdf.Text>
                        </ReactPdf.View>
                    ))}
                </ReactPdf.View>
            </ReactPdf.Page>
        </ReactPdf.Document>}>
            {({url, blob}) => {
                setPdf && setPdf(blob)
                return <Can action={['blank_view', 'blank_view_own', 'blank_view_only_result']}>
                    <a className={`btn btn-primary ${disabled && 'disabled'}`}
                       href={url}
                       target="_blank">
                        <i className="bx bx-printer"/>
                    </a>
                </Can>;
            }}
        </ReactPdf.BlobProvider>
    )
}

export default PDFTemplate
